body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.activeLink {
  font-weight: bold;
}

.hero_contentContainer__1_oP_ {
  max-width: 662px !important;
}

.hero_superpower__03uOm {
  margin-top: 15px;
}

.hero_container__c3Gho {
  margin: 0 !important
}

.privacy-policy .terms-of-service {
  display: flex;
  flex-direction: column;
  color: var(--color-text-primary);
}

@media (min-width: 998px) {
  .legal_container__PCd_C {
    margin: auto;
  }
}

.legal_container__PCd_C {
  max-width: 700px;
  font-size: 18px;
  line-height: 24px;
  margin: auto;
}

.legal_h1__elvz0,
.legal_h2___qfIT {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 24px;
}

.legal_p__hzARZ {
  margin-bottom: 24px;
}

.pricing_yearly__0y2UH {
  width: 145px !important;
}

.pricing_monthly__0y2UH {
  left: calc(100% - 122px) !important;
  width: 115px !important;
}